<template>
  <div class="function-link">
    <PageTitle
      title="Line串接設定"
      icon="chevron_left"
      hideBtn
      style="padding-bottom: 24px"
      @iconClick="goParameters"
    />

    <LineNewSettings v-if="isOhbotSuccessAdmin" />

    <section class="section-block">
      <SectionTitle
        title="Messaging API Channel 設定"
        @edit="showDialog = true"
      />

      <BaseElForm label-position="left" label-width="200px">
        <BaseElFormItem label="Line ID" prop="lineId">
          <div class="secret-text">
            <span>{{ settingData.lineId || '尚未設定' }}</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="Channel ID" prop="channelId">
          <div class="secret-text">
            <span>{{ settingData.channelId || '尚未設定' }}</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="Channel Secret" prop="channelSecret">
          <div class="secret-text">
            <span>{{ settingData.channelSecret || '尚未設定' }}</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="AccessToken" prop="channelAccessToken">
          <div class="secret-text">
            <span>{{ settingData.channelAccessToken || '尚未設定' }}</span>
          </div>
        </BaseElFormItem>

        <BaseElFormItem label="Webhook URL" prop="webhookUrl">
          <div class="flex items-center text-gray-80" style="gap: 8px">
            <p>{{ lineWebhookUrl || '尚未設定' }}</p>
            <div v-if="lineWebhookUrl" class="flex items-center justify-center cursor-pointer" @click="onCopyWebhookUrl">
              <MaterialIcon size="20" color="var(--gray-80)">content_copy_outlined</MaterialIcon>
            </div>
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <LineLoginSettings />

    <LineBindingSetting />

    <section class="section-block">
      <SectionTitle title="通知訊息設定" hideBtn />

      <BaseElForm label-position="left" label-width="200px">
        <BaseElFormItem
          v-for="item in notifyList"
          :key="item.key"
          :label="item.label"
        >
          <BaseElSwitch
            v-model="messageOption[item.key]"
            active-text="開啟通知"
            inactive-text="關閉通知"
            @change="updateLinePushConfig"
          />
          <BaseElButton v-if="item.key === 'couponRecordOpen'" class="advance-btn" type="text" @click="goAdvanceCouponRecordNotification">進階設定</BaseElButton>
        </BaseElFormItem>
        <BaseElFormItem label="票券即將到期通知">
          <BaseElSwitch
            v-model="messageOption.couponRecordExpireNotification"
            active-text="開啟通知"
            inactive-text="關閉通知"
            @change="
              updateLinePushGroup($event, ['couponRecordExpireNotification'])
            "
          />
          <BaseElButton class="advance-btn" type="text" @click="goAdvanceCouponRecordExpireNotification">進階設定</BaseElButton>
        </BaseElFormItem>

        <BaseElFormItem label="堂票建立/作廢">
          <BaseElSwitch
            v-model="messageOption.classTicketOpen"
            active-text="開啟通知"
            inactive-text="關閉通知"
            @change="updateLinePushGroup($event, ['cancelClassTicket'])"
          />
        </BaseElFormItem>

        <BaseElFormItem label="堂票使用/取消">
          <BaseElSwitch
            v-model="messageOption.classTicketUse"
            active-text="開啟通知"
            inactive-text="關閉通知"
            @change="updateLinePushGroup($event, ['cancelClassTicketUse'])"
          />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="
            checkPermission('ohbot.*') &&
              checkPermission('admin.allianceActivity.page')
          "
          label="聯盟行銷"
        >
          <BaseElSwitch
            v-model="messageOption.allianceActivityAddQualification"
            active-text="開啟通知"
            inactive-text="關閉通知"
            @change="updateLinePushConfig"
          />
        </BaseElFormItem>
        <BaseElFormItem label="堂票即將到期">
          <BaseElSwitch
            v-model="messageOption.classTicketExpireNotification"
            active-text="開啟通知"
            inactive-text="關閉通知"
            @change="
              updateLinePushGroup($event, ['classTicketExpireNotification'])
            "
          />
        </BaseElFormItem>

        <!-- 點數 -->
        <BaseElFormItem
          v-for="item in shopPoint"
          :key="item.key"
          :label="item.label"
        >
          <BaseElSwitch
            v-model="messageOption[item.key]"
            active-text="開啟通知"
            inactive-text="關閉通知"
            @change="updateLinePushGroup($event, [item.key])"
          />
        </BaseElFormItem>

        <!-- 會員升降級 -->
        <BaseElFormItem
          v-for="item in memberLevel"
          :key="item.key"
          :label="item.label"
        >
          <BaseElSwitch
            v-model="messageOption[item.key]"
            active-text="開啟通知"
            inactive-text="關閉通知"
            @change="updateLinePushConfig"
          />
        </BaseElFormItem>

        <!-- 會員邀請 -->
        <BaseElFormItem
          v-for="item in memberInvite"
          :key="item.key"
          :label="item.label"
        >
          <BaseElSwitch
            v-model="messageOption[item.key]"
            active-text="開啟通知"
            inactive-text="關閉通知"
            @change="updateLinePushConfig"
          />
        </BaseElFormItem>
        <!-- 次卡 -->
        <template v-if="showControl.notifyScope.punchCard">
          <BaseElFormItem
            v-for="item in punchCard"
            :key="item.key"
            :label="item.label"
          >
            <BaseElSwitch
              v-model="messageOption[item.key]"
              active-text="開啟通知"
              inactive-text="關閉通知"
              @change="updateLinePushConfig"
            />
          </BaseElFormItem>
        </template>
        <!-- 點數卡 -->
        <template v-if="showControl.notifyScope.pointCard">
          <BaseElFormItem
            v-for="item in pointCard"
            :key="item.key"
            :label="item.label"
          >
            <BaseElSwitch
              v-model="messageOption[item.key]"
              active-text="開啟通知"
              inactive-text="關閉通知"
              @change="updateLinePushConfig"
            />
          </BaseElFormItem>
        </template>
        <!-- 會員權益 -->
        <template v-if="showControl.notifyScope.periodicBenefit">
          <BaseElFormItem
            v-for="item in periodicBenefit"
            :key="item.key"
            :label="item.label"
          >
            <BaseElSwitch
              v-model="messageOption[item.key]"
              active-text="開啟通知"
              inactive-text="關閉通知"
              @change="updateLinePushConfig"
            />
          </BaseElFormItem>
        </template>
      </BaseElForm>
    </section>

    <LineModule />

    <!-- Dialog -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      title="編輯 Messaging API Channel 設定"
    >
      <BaseElForm
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <BaseElFormItem label="Line ID" prop="lineId">
          <BaseElInput
            v-model="formData.lineId"
            class="dialog-input"
            placeholder="請輸入"
          />
        </BaseElFormItem>

        <BaseElFormItem label="Channel ID" prop="channelId">
          <BaseElInput
            v-model="formData.channelId"
            class="dialog-input"
            placeholder="請輸入"
          />
        </BaseElFormItem>

        <BaseElFormItem label="Channel Secret" prop="channelSecret">
          <BaseElInput
            v-model="formData.channelSecret"
            class="dialog-input"
            placeholder="請輸入"
          />
        </BaseElFormItem>

        <BaseElFormItem label="AccessToken" prop="channelAccessToken">
          <BaseElInput
            v-model="formData.channelAccessToken"
            class="dialog-input"
            placeholder="請輸入"
          />
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton plain @click="showDialog = false">取消</BaseElButton>
        <BaseElButton type="primary" @click="updateLineConfig">儲存</BaseElButton>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LineBindingSetting from './components/LineBindingSetting.vue'
import LineModule from './components/LineModule.vue'
import LineLoginSettings from './components/LineLoginSettings.vue'
import LineNewSettings from './components/LineNewSettings.vue'
import PageTitle from '@/components/Title/PageTitle'
import SectionTitle from '@/components/Title/SectionTitle'
import { mapGetters, mapState } from 'vuex'
import { defineComponent, computed, reactive, ref } from 'vue'
import copy from 'clipboard-copy'
import {
  FindLineConfig,
  UpdateLineConfig,
  CreateLiff,
  GetLiff,
  GetLinePushConfig,
  UpdateLinePushConfig,
} from '@/api/shop'
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { checkUserFeature } from '@/store/modules/permission'
import { usePermissions } from '@/use/permissions'
import { GetLineWebhookUrl } from '@/api/line'
import { useShop } from '@/use/shop'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'FunctionLink',
  components: {
    PageTitle,
    SectionTitle,
    LineModule,
    LineLoginSettings,
    LineBindingSetting,
    LineNewSettings,
  },
  setup () {
    const { checkAction } = usePermissions()
    const { shopId } = useShop()
    const isOhbotSuccessAdmin = computed(() => checkAction('admin.authServiceLineOauthConfig.showUseShopLineLogin'))
    const router = useRouter()

    const formData = ref({
      lineId: '',
      channelId: '',
      channelSecret: '',
      channelAccessToken: '',
    })

    const settingData = ref({
      lineId: '',
      channelId: '',
      channelSecret: '',
      channelAccessToken: '',
      webhookUrl: '',
    })

    const lineWebhookUrl = ref('')

    const getLineWebhookUrl = async () => {
      const [res, err] = await GetLineWebhookUrl({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      lineWebhookUrl.value = res.webhookUrl
    }

    const onCopyWebhookUrl = () => {
      copy(lineWebhookUrl.value)
      window.$message.success('已成功複製 Webhook URL！')
    }
    const goAdvanceCouponRecordNotification = () => {
      router.push({ name: 'AdvanceCouponRecordNotification' })
    }
    const goAdvanceCouponRecordExpireNotification = () => {
      router.push({ name: 'AdvanceCouponRecordExpireNotification' })
    }
    const goParameters = () => {
      router.push({ name: 'Parameters' })
    }

    const showControl = computed(() => {
      const controls = {
        notifyScope: {
          punchCard: false,
          pointCard: false,
          periodicBenefit: false,
        },
      }

      // 次卡
      if (checkAction('planModule.punchCard.enable')) {
        controls.notifyScope.punchCard = true
      }

      // 點卡
      if (checkAction('planModule.pointCard.enable')) {
        controls.notifyScope.pointCard = true
      }

      // 會員權益
      if (checkAction('planModule.periodicBenefit.enable')) {
        controls.notifyScope.periodicBenefit = true
      }

      return controls
    })

    const notifyList = computed(() => {
      return [
        { key: 'appointmentOpen', label: '預約建立' },
        { key: 'appointmentChangeTime', label: '預約變更' },
        { key: 'appointmentCancel', label: '預約取消' },
        { key: 'appointmentTomorrow', label: '預約前提醒' },
        { key: 'appointmentEnd', label: '預約結束提醒' },
        { key: 'walletDeposit', label: '儲值金儲值' },
        { key: 'walletUse', label: '儲值金使用' },
        { key: 'appointmentDepositRefund', label: '訂金退款' },
        { key: 'shopCashbackAddPoint', label: '回饋金贈送' },
        { key: 'shopCashbackExpirationNotify', label: '回饋金即將到期' },
        { key: 'shopCashbackUsePoint', label: '回饋金扣除' },
        { key: 'shopCashbackCancelPoint', label: '回饋金回收' },
        { key: 'couponRecordOpen', label: '票券發放通知' },
      ]
    })
    const shopPoint = computed(() => {
      return [
        { key: 'shopPointAddPoint', label: '點數贈送' },
        { key: 'shopPointCancelPoint', label: '點數扣除' },
        { key: 'shopPointUsePoint', label: '點數回收' },
        { key: 'shopPointExpirationNotify', label: '點數即將到期' },
      ]
    })

    const memberLevel = computed(() => {
      return [
        { key: 'memberLevelUp', label: '會員達成升等' },
        { key: 'memberLevelDown', label: '會員達成降等' },
        { key: 'memberLevelRemain', label: '會員達成續等' },
        { key: 'memberLevelExpire', label: '會員等級即將到期' },
      ]
    })

    const memberInvite = computed(() => {
      return [
        { key: 'memberSuccessReferral', label: '會員推薦好友成功' },
      ]
    })

    const punchCard = computed(() => {
      return [
        { key: 'punchCardOpen', label: '次卡新增/作廢' },
        { key: 'punchCardUse', label: '次卡使用/補發' },
        { key: 'punchCardExpireNotification', label: '次卡到期' },
      ]
    })

    const pointCard = computed(() => {
      return [
        { key: 'pointCardOpen', label: '點數卡新增/作廢' },
        { key: 'pointCardUse', label: '點數卡使用/補發' },
        { key: 'pointCardExpireNotification', label: '點數卡到期' },
      ]
    })

    const periodicBenefit = computed(() => {
      return [
        { key: 'periodicBenefitRecordOpen', label: '會員權益新增' },
        { key: 'periodicBenefitRecordPause', label: '會員權益暫停' },
        { key: 'periodicBenefitRecordTerminate', label: '會員權益終止' },
      ]
    })

    return {
      isOhbotSuccessAdmin,
      getLineWebhookUrl,
      formData,
      settingData,
      onCopyWebhookUrl,
      lineWebhookUrl,
      notifyList,
      shopPoint,
      memberLevel,
      memberInvite,
      punchCard,
      pointCard,
      periodicBenefit,
      showControl,
      goAdvanceCouponRecordNotification,
      goAdvanceCouponRecordExpireNotification,
      goParameters,
    }
  },
  data: () => ({
    liff: null,
    showDialog: false,

    messageOption: {
      phoneVerifyHelp: false,
      appointmentOpen: false,
      appointmentCancel: false,
      appointmentChangeTime: false,
      appointmentTomorrow: false,
      appointmentEnd: false,
      walletDeposit: false,
      walletUse: false,
      classTicketOpen: false,
      classTicketUse: false,
      cancelClassTicket: false,
      cancelClassTicketUse: false,
      allianceActivityAddQualification: false,
      couponRecordOpen: false,
      appointmentDepositRefund: false,
      shopCashbackExpirationNotify: false,
      shopCashbackAddPoint: false,
      shopCashbackUsePoint: false,
      shopCashbackCancelPoint: false,
      classTicketExpireNotification: false,
      couponRecordExpireNotification: false,
      memberLevelUp: false,
      memberLevelDown: false,
      memberLevelRemain: false,
      memberLevelExpire: false,
      shopPointAddPoint: false,
      shopPointCancelPoint: false,
      shopPointUsePoint: false,
      shopPointExpirationNotify: false,
      memberSuccessReferral: false,
      punchCardOpen: false,
      punchCardUse: false,
      punchCardExpireNotification: false,
      pointCardOpen: false,
      pointCardUse: false,
      pointCardExpireNotification: false,
      periodicBenefitRecordOpen: false,
      periodicBenefitRecordPause: false,
      periodicBenefitRecordTerminate: false,
    },

    formRules: {
      lineId: noEmptyRules(),
      channelId: noEmptyRules(),
      channelSecret: noEmptyRules(),
      channelAccessToken: noEmptyRules(),
    },

  }),
  computed: {
    ...mapGetters(['shop', 'role', 'userFeatures', 'userPlanFeature']),
    useWallet () {
      return checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'admin.walletRecord.page',
      )
    },

    liffUrl () {
      if (!this.liffLink('appointment')) return false
      return this.liffLink('appointment').liffUrl
    },

    profileUrl () {
      if (!this.liffLink('appointment')) return false
      return `${this.liffLink('appointment').liffUrl}/profile`
    },
    checkPermission () {
      return (action) =>
        this.role === 'ohbotSuccessAdmin'
          ? true
          : checkUserFeature(this.userPlanFeature, this.userFeatures, action)
    },
  },

  watch: {
    messageOption () {
      console.log('change')
    },
  },

  async mounted () {
    await Promise.allSettled([
      this.findLineConfig(),
      this.getLiff(),
      this.getLinePushConfig(),
      this.getLineWebhookUrl(),
    ])
  },

  methods: {
    copyLink (link) {
      if (!this.liffUrl) return
      copy(link)
      this.$message.success('已成功複製連結！')
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        lineId: '',
        channelId: '',
        channelSecret: '',
        channelAccessToken: '',
      }
    },

    liffLink (name) {
      if (!this.liff) return false
      return this.liff.find((item) => item.name === name)
    },

    async findLineConfig () {
      try {
        const res = await FindLineConfig({ shopId: this.shop })
        this.formData = res
        this.settingData = res
      } catch (error) {
        if (error.includes('找不到')) {
          this.formData = {
            channelId: '',
            channelSecret: '',
            channelAccessToken: '',
          }
          this.settingData = ''
        }
      }
    },

    async updateLineConfig () {
      if (!(await this.checkForm())) return
      const form = this.formData
      try {
        await UpdateLineConfig({
          shopId: this.shop,
          lineId: form.lineId,
          channelId: form.channelId,
          channelSecret: form.channelSecret,
          channelAccessToken: form.channelAccessToken,
        })
        this.$message.success('更新成功')
        await this.findLineConfig()
        this.showDialog = false
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async createLiff (name) {
      let pass = false
      if (this.formData.channelId !== '') pass = true
      if (this.formData.channelSecret !== '') pass = true
      if (this.formData.channelAccessToken !== '') pass = true
      if (!pass) {
        this.$message.warning('請先設置各項Channel參數')
        return
      }

      try {
        await CreateLiff({ shopId: this.shop, name })
        await this.getLiff()
        this.$message.success('更新 Liff 連結成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    async getLiff () {
      try {
        const res = await GetLiff({ shopId: this.shop })
        this.liff = res
      } catch (error) {
        this.liff = []
      }
    },

    async getLinePushConfig () {
      try {
        const config = await GetLinePushConfig({ shopId: this.shop })
        Object.keys(this.messageOption).forEach((option) => {
          this.messageOption[option] = config[option]
        })
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    async updateLinePushGroup (val, options) {
      options.forEach((name) => {
        this.messageOption[name] = val
      })
      await this.updateLinePushConfig()
    },

    async updateLinePushConfig () {
      console.log(this.messageOption)
      try {
        await UpdateLinePushConfig({
          shopId: this.shop,
          ...this.messageOption,
        })
        this.$message.success('已更新通知設定 !')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },
  },
})
</script>

<style scoped lang="scss">
section {
  margin-top: 2rem;
  margin-bottom: 50px;
}

.title {
  font-size: 20px;
  margin-right: 10px;
}

.link-card {
  margin-bottom: 20px;
}

.secret-text {
  width: 100px;
  font-weight: 400;
  @apply text-gray-80;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.copy-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  background: white;
  overflow: hidden;
  max-width: 440px;
  height: 44px;
  border-radius: 4px;

  a {
    font-weight: 400;
    @apply text-gray-80;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px 20px;
  }

  .copy-btn {
    @apply bg-primary-100 text-white cursor-pointer;
    white-space: nowrap;
    padding: 8px 50px;
    &:hover,
    &:focus,
    &:active {
      // background-color: darken($color: $primary-1, $amount: 5);
    }
  }

  .disable {
    @apply bg-gray-40 cursor-not-allowed;
    &:hover,
    &:focus,
    &:active {
      @apply bg-gray-40;
    }
  }
}

.disable {
  @apply bg-gray-40 cursor-not-allowed border border-gray-40;
  &:hover,
  &:focus,
  &:active {
    @apply bg-gray-40 border border-gray-40;
  }
}

.dialog-input {
  width: 340px;
}

.btn {
  width: 140px;
}

::v-deep .el-form-item__label {
  @apply text-normal font-medium;
}
::v-deep .advance-btn {
  @apply text-sub pl-[48px];
}
</style>
